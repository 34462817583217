<template>
    <div class="left-bar" :class="!status && 'md-none'">
        <div class="profile-info">
            <div class="photo-wrap">
                <img :src="profile.avatar + '?v=1.0.0'" :alt="profile.email">
            </div>
            <b>{{ profile.firstname +' '+ profile.lastname }}</b>
            <span>{{ profile.email }}</span>
        </div>
        <div class="left-bar-balance" v-if="balance">
            <div>
                <b :class="balance.USDT.balance > 0 && 'active'">{{ balance.USDT.balance }} USDT</b>
                <span>Ваш баланс</span>
            </div>
        </div>
        <div class="btn-left-wrap">
            <router-link to="/dashboard/payment-services/317" class="button block lg button-success btn-left-bar" >Вывод на карту</router-link>
            <router-link to="/dashboard/payment-services/327" class="button block lg button-success btn-left-bar md-block" >Вывод на Эл. кошелек</router-link>
        </div>
        <div class="dashboard-menu pb-5">
            <template v-for="item in menu" :key="item">
                <span class="dashboard-nav-label" v-if="item.label">{{ item.label }}</span>
                <template v-if="item.link">
                    <router-link :to="item.link" class="dashboard-nav ease" :class="item.icon" v-if="item.disabled !== true">{{ item.name }} <small class="nav-badge" v-if="item.badge">{{ item.badge }}</small></router-link>
                </template>
                <template v-else>
                    <a class="dashboard-nav ease" :class="item.icon">{{ item.name }} <small class="nav-badge" v-if="item.badge">{{ item.badge }}</small></a>
                </template>
            </template>
            <a href="#" class="dashboard-nav ease logout" v-on:click="logout">Выйти</a>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            status: true,
            menu: [
                {name: 'Главная', icon: 'home', link: '/dashboard', label: 'Главная'},
                {name: 'Пополнить', icon: 'refill', link: '/dashboard/refill/cryptocurrency'},
                {name: 'Оплата услуг', icon: 'payment', link: '/dashboard/payment-services', badge: 'New'},
                {name: 'Купить', icon: 'buy', link: '/dashboard/buy', label: 'Обмен'},
                {name: 'Продать', icon: 'sell', link: '/dashboard/sell'},
                {name: 'Вывести', icon: 'withdraw', link: '/dashboard/withdraw'},
                {name: 'История операции', icon: 'history', link: '/dashboard/operations'},
                {name: 'Розыгрыш призов', icon: 'gift-block', link: '', badge: 'Скоро'},
                {name: 'Настройки', icon: 'settings', link: '/dashboard/settings/verification', label: 'Настройки'}
            ]
        }
    },
    mounted() {
        this.navBar()
    },
    computed: {
        profile() {
            return (this.$store.getters.getProfile)
            ? this.$store.getters.getProfile
            : this.$store.dispatch('getProfile')
        },
        balance() {
            return this.$store.getters.getCurrencies
        }
    },
    watch: {
        $route() {
            this.navBar()
        }
    },
    methods: {
        navBar() {
            if(this.$router.currentRoute.value.fullPath === '/dashboard') {
                this.status = true
            } else {
                this.status = false
            }
        },
        logout() {
            this.$store.dispatch('logout')
        }
    }
}
</script>