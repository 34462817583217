import { createWebHistory, createRouter } from 'vue-router';
import AuthGuard from './auth-guard'

// Main pages
import Main from '@/pages/Main/Main'
import About from '@/pages/Main/About'
import News from '@/pages/Main/News'
import NewsDetail from '@/pages/Main/NewsDetail'
import FAQ from '@/pages/Main/FAQ'
import Rules from '@/pages/Main/Rules'
import PrivacyPolicy from '@/pages/Main/PrivacyPolicy'
import Licenses from '@/pages/Main/Licenses'
import Contacts from '@/pages/Main/Contacts'
import Reviews from '@/pages/Main/Reviews'
import AddReview from '@/pages/Main/AddReview'

// Account pages
import DashboardMain from '@/pages/Account/DashboardMain'
import Dashboard from '@/pages/Account/Dashboard'
import Refill from '@/pages/Account/Refill'
import Buy from '@/pages/Account/Buy'
import BuyDetail from '@/pages/Account/BuyDetail'
import Sell from '@/pages/Account/Sell'
import SellDetail from '@/pages/Account/SellDetail'
import Withdraw from '@/pages/Account/Withdraw'
import WithdrawDetail from '@/pages/Account/WithdrawDetail'
import History from '@/pages/Account/History'
import PaymentServices from '@/pages/Account/PaymentServices'
import PaymentCategories from '@/pages/Account/PaymentCategories'
import PaymentDetail from '@/pages/Account/PaymentDetail'
import Settings from '@/pages/Account/Settings'
import SettingProfile from '@/pages/Account/SettingProfile'
import SettingPassword from '@/pages/Account/SettingPassword'
import SettingVerification from '@/pages/Account/SettingVerification'
import SettingSecurity from '@/pages/Account/SettingSecurity'
import Security2fa from '@/pages/Account/Security2fa'
import Points from '@/pages/Account/Points'

const routes = [
    {
        name: 'Main',
        path: '/',
        component: Main
    },
    {
        name: 'About',
        path: '/about',
        component: About
    },
    {
        name: 'News',
        path: '/news',
        component: News
    },
    {
        name: 'News Detail',
        path: '/news/:id',
        component: NewsDetail
    },
    {
        name: 'FAQ',
        path: '/faq',
        component: FAQ
    },
    {
        name: 'Rules',
        path: '/rules',
        component: Rules
    },
    {
        name: 'Privacy Policy',
        path: '/privacy-policy',
        component: PrivacyPolicy
    },
    {
        name: 'Licenses',
        path: '/licenses',
        component: Licenses
    },
    {
        name: 'Contacts',
        path: '/contacts',
        component: Contacts
    },
    {
        name: 'Reviews',
        path: '/reviews',
        component: Reviews
    },
    {
        name: 'Add review',
        path: '/reviews/add',
        component: AddReview,
        beforeEnter: AuthGuard
    },
    {
        name: 'Dashboard Main',
        path: '/dashboard',
        component: DashboardMain,
        beforeEnter: AuthGuard,
        children: [
            {
                name: 'Dashboard',
                path: '/dashboard',
                component: Dashboard
            },
            {
                name: 'Refill',
                path: '/dashboard/refill',
                component: Refill
            },
            {
                name: 'Refill Category',
                path: '/dashboard/refill/:category',
                component: Refill
            },
            {
                name: 'Buy',
                path: '/dashboard/buy',
                component: Buy
            },
            {
                name: 'Buy Detail',
                path: '/dashboard/buy/:currency',
                component: BuyDetail
            },
            {
                name: 'Sell',
                path: '/dashboard/sell',
                component: Sell
            },
            {
                name: 'Sell Detail',
                path: '/dashboard/sell/:currency',
                component: SellDetail
            },
            {
                name: 'Withdraw',
                path: '/dashboard/withdraw',
                component: Withdraw
            },
            {
                name: 'Withdraw Category',
                path: '/dashboard/withdraw/:category',
                component: Withdraw
            },
            {
                name: 'Withdraw Detail',
                path: '/dashboard/withdraw/detail/:currency',
                component: WithdrawDetail
            },
            {
                name: 'History',
                path: '/dashboard/operations',
                component: History
            },
            {
                name: 'Payment Services',
                path: '/dashboard/payment-services',
                component: PaymentServices
            },
            {
                name: 'Payment Categories',
                path: '/dashboard/payment-services/:category',
                component: PaymentCategories
            },
            {
                name: 'Payment Detail',
                path: '/dashboard/payment-services/:category/:id',
                component: PaymentDetail
            },
            {
                name: 'Points',
                path: '/dashboard/points',
                component: Points
            },
            {
                name: 'Settings',
                path: '/dashboard/settings',
                component: Settings,
                children: [
                    {
                        name: 'Profile',
                        path: '/dashboard/settings',
                        component: SettingProfile
                    },
                    {
                        name: 'Change Password',
                        path: '/dashboard/settings/password',
                        component: SettingPassword
                    },
                    {
                        name: 'Verification',
                        path: '/dashboard/settings/verification',
                        component: SettingVerification
                    },
                    {
                        name: 'Security',
                        path: '/dashboard/settings/security',
                        component: SettingSecurity
                    },
                    {
                        name: 'Security 2Fa',
                        path: '/dashboard/settings/security/2fa',
                        component: Security2fa,
                    }
                ]
            }
        ]
    }
]

const router = createRouter({
    mode: 'hash',
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    }
})

export default router