<template>
    <div class="modal-window" v-if="modalStatus && !isAuth">
        <div class="modal-close" v-on:click="closeModal"></div>
        <div class="modal-content ellipse ellipse-after" v-if="url === 'registration'">
            <h1 class="modal-title">Создать аккаунт</h1>
            <span class="modal-subtitle">Уже есть аккаунт? <router-link to="?page=login" class="ease">Войти</router-link></span>
            <form v-on:submit.prevent="registration" class="form position-relative z-index" autocomplete="off">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <label class="form-label">Имя</label>
                        <div class="form-group">
                            <input type="text" class="form-input" v-model="firstname" placeholder="Имя" :disabled="loading" required>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <label class="form-label">Фамилия</label>
                        <div class="form-group">
                            <input type="text" class="form-input" v-model="lastname" placeholder="Фамилия" :disabled="loading" required>
                        </div>
                    </div>
                </div>
                <label class="form-label">E-mail</label>
                <div class="form-group">
                    <input type="email" class="form-input" v-model="email" placeholder="Введите e-mail" :disabled="loading" required>
                </div>
                <label class="form-label">Пароль</label>
                <div class="form-group">
                    <input type="password" class="form-input" v-model="password" placeholder="Введите пароль" :disabled="loading" required>
                </div>
                <div class="form-group">
                    <input type="password" class="form-input" v-model="passwordRepeat" placeholder="Повторите пароль" :disabled="loading" required>
                </div>
                <label class="form-agree mt-4">
                    <input type="checkbox" class="form-checkbox" v-model="publicPerson" required>
                    <span>Я не являюсь публичным должностным лицом</span>
                </label>
                <label class="form-agree mb-4">
                    <input type="checkbox" class="form-checkbox" v-model="agree" required>
                    <span>Я ознакомлен(а) с <router-link to="/rules">политикой, офертой</router-link>, и даю согласие на обработку персональных данных</span>
                </label>
                <button class="button block lg" type="submit" :disabled="loading">Регистрация</button>
            </form>
        </div>
        <div class="modal-content ellipse ellipse-after" v-if="url === 'login'">
            <h1 class="modal-title">Войти в личный кабинет</h1>
            <span class="modal-subtitle">Ещё нет аккаунта? <router-link to="?page=registration" class="ease">Зарегистрироваться</router-link></span>
            <form v-on:submit.prevent="login" class="form position-relative z-index" autocomplete="off">
                <label class="form-label">E-mail</label>
                <div class="form-group">
                    <input type="email" class="form-input" v-model="email" placeholder="Введите e-mail" :disabled="loading" required>
                </div>
                <label class="form-label">Пароль</label>
                <div class="form-group">
                    <input type="password" class="form-input" v-model="password" placeholder="Введите пароль" :disabled="loading" required>
                </div>
                <template v-if="twofactor">
                    <label class="form-label">Google 2Fa Authenticator</label>
                    <div class="form-group">
                        <input type="number" class="form-input" ref="code" placeholder="Введите защитный код" :disabled="loading" required>
                    </div>
                </template>
                <span class="form-agree text-right pb-2"><router-link to="?page=restore">Забыли пароль?</router-link></span>
                <button class="button block lg" type="submit" :disabled="loading">Войти в личный кабинет</button>
            </form>
        </div>
        <div class="modal-content ellipse ellipse-after" v-if="url === 'activation'">
            <h1 class="modal-title text-center">Подтверждение почты</h1>
            <span class="modal-subtitle text-center">Мы отправили вам на почту код подтверждения, не забудьте проверить папку СПАМ. Пожалуйста, введите его в поле ниже</span>
            <form v-on:submit.prevent="activation" class="form position-relative z-index" autocomplete="off">
                <label class="form-label">E-mail</label>
                <div class="form-group">
                    <input type="email" class="form-input" disabled :value="email">
                </div>
                <label class="form-label">Код подтверждения</label>
                <div class="form-group">
                    <input type="number" class="form-input" ref="code" placeholder="Введите код подтверждения" :disabled="loading" required>
                </div>
                <button class="button block lg mt-4" type="submit" :disabled="loading">Подтвердить</button>
                <span class="modal-subtitle text-center mt-4 mb-0">Не пришел код? <a class="cursor" :disabled="loading" v-on:click="resend">Отправить еще раз</a></span>
            </form>
        </div>
        <div class="modal-content ellipse ellipse-after" v-if="url === 'restore'">
            <h1 class="modal-title text-center">Забыли пароль?</h1>
            <span class="modal-subtitle text-center">Укажите ваш E-mail, который использовался при регистрации</span>
            <form v-on:submit.prevent="restore" class="form position-relative z-index" autocomplete="off">
                <label class="form-label">E-mail</label>
                <div class="form-group">
                    <input type="email" class="form-input" v-model="email" placeholder="Введите e-mail" :disabled="loading" required>
                </div>
                <button class="button block lg mt-4" type="submit" :disabled="loading">Сбросить пароль</button>
                <span class="modal-subtitle text-center mt-4 mb-0">Есть аккаунт? <router-link to="?page=login">Вернуться к авторизации</router-link></span>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            url: '',
            modalStatus: false,
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            passwordRepeat: '',
            publicPerson: false,
            agree: false,
            isAuth: false
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        twofactor() {
            return this.$store.getters.twofactor
        }
    },
    watch: {
        $route() {
            this.showModal()
            this.email = this.$route.query['email']
            if (this.$route.query['AccessDenied']) {
                this.$store.dispatch('notification', { message: 'Please log in to access' })
            }
            if (this.$route.query['SessionExpired']) {
                this.$store.dispatch('notification', { message: 'Ваша сессия истекла. Пожалуйста, авторизуйтесь снова, чтобы продолжить работу.' })
            }
        }
    },
    methods: {
        showModal() {
            if (this.$route.query['page'] && !localStorage.getItem('isAuth') && !localStorage.getItem('token')) {
                this.$store.dispatch('twofactor', false)
                this.modalStatus = true
                this.url = this.$route.query['page']
            } else {
                this.modalStatus = false
            }
        },
        closeModal() {
            this.modalStatus = false
            this.url = ''
            this.$router.replace({ 'query': null })
        },
        notification(message, type) {
            this.$store.dispatch('notification', { message, type })
        },
        registration() {
            if (!this.firstname || this.firstname.trim() === '') {
                this.notification('Введите имя')
            } else {
                if (!this.lastname || this.lastname.trim() === '') {
                    this.notification('Введите Фамилию')
                } else {
                    if (!this.email || this.email.trim() === '') {
                        this.notification('Введите E-mail')
                    } else {
                        if (!this.password || this.password.trim() === '') {
                            this.notification('Введите пароль')
                        } else {
                            if (!this.passwordRepeat || this.passwordRepeat.trim() === '') {
                                this.notification('Повторите пароль')
                            } else {
                                if (this.passwordRepeat !== this.password) {
                                    this.notification('Пароли не совпадают')
                                } else {
                                    if (this.agree === false) {
                                        this.notification('Вы должны согласится с условиями оферты')
                                    } else {
                                        const data = {
                                            'firstname': this.firstname,
                                            'lastname': this.lastname,
                                            'email': this.email,
                                            'password': this.password,
                                            'password_confirm': this.passwordRepeat
                                        }
                                        this.$store.dispatch('registration', data)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        activation() {
            if (!this.$refs.code.value || String(this.$refs.code.value).trim() === '') {
                this.notification('Введите код подтверждения')
            } else {
                const data = {
                    'email': this.$route.query['email'],
                    'code': this.$refs.code.value
                }
                this.$store.dispatch('activation', data)
            }
        },
        resend() {
            if (!this.$route.query['email'] || this.$route.query['email'] === '') {
                this.notification('Введите E-mail')
            } else {
                this.$store.dispatch('resend', { email: this.$route.query['email'] })
            }
        },
        login() {
            if (!this.email || this.email.trim() === '') {
                this.notification('Введите E-mail')
            } else {
                if (!this.password || this.password.trim() === '') {
                    this.notification('Введите пароль')
                } else {
                    const data = {
                        'email': this.email,
                        'password': this.password,
                    }
                    if (this.twofactor) {
                        data['2fa_otp'] = String(this.$refs.code.value)
                    }
                    this.$store.dispatch('login', data)
                }
            }
        },
        restore() {
            if (!this.email || this.email.trim() === '') {
                this.notification('Введите E-mail')
            } else {
                this.$store.dispatch('restore', { email: this.email })
            }
        }
    }
}
</script>