<template>
    <h1 class="dashboard-title justify-content-between">
        <span>Вывод средств</span>
        <div>
            <input type="text" name="search" class="form-input" v-model="searchValue" placeholder="Поиск"
                autocomplete="off">
        </div>
    </h1>
    <div class="tab horizontal default-color">
        <router-link to="/dashboard/withdraw" class="tab-button">Криптовалюты</router-link>
        <router-link to="/dashboard/payment-services/317" class="tab-button">Вывести на карту</router-link>
        <router-link to="/dashboard/withdraw/bank" class="tab-button">Банк</router-link>
    </div>
    <div class="dashboard-list">
        <div class="row" v-if="category == '' || category == undefined">
            <template v-for="item in list" :key="item">
                <div class="col-12 col-md-6 col-lg-6 col-xl-4" v-if="item.can_withdraw === true && search(item)">
                    <router-link :to="'/dashboard/withdraw/detail/' + item.currency" class="list-item darks">
                        <img :src="item.logo" :alt="item.name">
                        <div class="list-item-wrap">
                            <div>
                                <b>{{ item.currency }}</b>
                                <span>{{ item.balance }}</span>
                            </div>
                            <small class="list-item-button d-none"></small>
                        </div>
                    </router-link>
                </div>
            </template>
        </div>
        <div class="row" v-if="category == 'fiat'">
            <template v-for="item in cashList" :key="item">
                <div class="col-6 col-md-6 col-lg-6 col-xl-4" v-on:click="getMethodDetail(item)">
                    <div class="list-item">
                        <img :src="item?.logo" :alt="item?.name">
                        <div class="list-item-wrap">
                            <div>
                                <b>{{ item.currency }}</b>
                                <span class="active">{{ item.name }}</span>
                            </div>
                            <small class="list-item-button d-none"></small>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="!cashList || cashList == undefined || cashList == ''">
                <span class="icon-text empty">Нет данных</span>
            </template>
        </div>
        <div class="row" v-if="category == 'bank'">
            <template v-for="item in cashList" :key="item">
                <div class="col-6 col-md-6 col-lg-6 col-xl-4" v-on:click="getMethodDetail(item)">
                    <div class="list-item">
                        <img :src="item?.logo" :alt="item?.name">
                        <div class="list-item-wrap">
                            <div>
                                <b>{{ item.currency }}</b>
                                <span class="active">{{ item.name }}</span>
                            </div>
                            <small class="list-item-button d-none"></small>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="!cashList || cashList == undefined || cashList == ''">
                <span class="icon-text empty">Нет данных</span>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            modalStatus: false,
            searchValue: '',
            category: '',
            detail: ''
        }
    },
    mounted() {
        this.category = this.$router.currentRoute.value.params.category
    },
    computed: {
        list() {
            return this.$store.getters.getCurrencies
        },
        cashList() {
            return (this.$store.getters.getWithdrawCashList)
                ? this.$store.getters.getWithdrawCashList
                : this.$store.dispatch('getWithdrawCashList')
        }
    },
    watch: {
        $route() {
            this.category = this.$router.currentRoute.value.params.category
        }
    },
    methods: {
        showModal() {
            this.modalStatus = true
        },
        closeModal() {
            this.modalStatus = false
            if(this.category === 'fiat') {
                this.amount = ''
                this.$store.dispatch('clearFiatMethod')
            }
        },
        search(item) {
            return (item.currency.search(this.searchValue) !== -1 || item.currency.toLowerCase().search(this.searchValue) !== -1) ? true : false
        },
        getMethodDetail(item) {
            this.detail = item
            this.showModal()
        }
    }
}
</script>